.approval {
  padding-top: 20px;
  width: 512px;
  min-height: 219px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: row;
}

.approvalButton {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
}

.approvalText {
  font-size: 28px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 42px;
  margin-left: 30px;
  margin-right: 30px;
}

.modalCloseIcon {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 450px;
}
