.GenericPieChart__tooltipMainContainer {
    background-color: #FFFFFF !important;
    padding: 0px !important;
    border-radius: 10px;
}

.GenericPieChart__tooltipMainContainer>div {
    padding: 0px !important;
}

.GenericPieChart__toolTipContainer {
    font-family: Montserrat !important;
    width: 111px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F5F5F5;
    padding: 7px;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
}

.GenericPieChart__toolTipContainer>h4 {
    margin: 0px;
    font: normal normal 600 10px/13px Montserrat;
    letter-spacing: 0.2px;
    color: #000000;
}

.GenericPieChart__toolTipContainer>div {
    font: normal normal normal 16px/19px Montserrat;
    letter-spacing: 0.32px;
    color: #000000;
    margin-top: 7px;
}

.GenericPieChart__toolTipContainerColor {
    height: 12px;
    width: 12px;
    margin-right: 4px;
    border-radius: 3px;
    align-self: center;
}

.GenericPieChart__percentageText {
    font: normal normal 600 12px/5px Montserrat !important;
}

.GenericPieChart__legendContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.GenericPieChart__legend {
    font-size: 14px;
    font-weight: 500;
}
