.headerContainer {
    display: flex;
    padding: 10px 30px;
    justify-content: space-between;
}

.filters {
    width: 100% !important;

}

.facilityFilters {
    display: flex;
    justify-content: space-between;
    float: right;
}

.dateRange {
    width: 230px;
    margin-left: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: right;
}

.esgFilters {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.esgButtonDiv {
    padding-bottom: 10px;
}
