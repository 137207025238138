.genericBarChart_tooltipContainer {
    padding: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
}

.genericBarChart_tooltipContainer>p {
    margin: 0px;
    font: normal normal 600 10px/13px Montserrat;
    letter-spacing: 0.2px;
    color: #000000;
}

.genericBarChart_tooltipContainer>div {
    padding: 0px !important;
}

.genericBarChart_customToolTipContainer {
    display: flex;
    align-items: center;
    padding: 0px !important;
}

.genericBarChart_bulletContainer {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    border-radius: 3px;
}

.genericBarChart_value {
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0.2px;
    color: #000000;
}
