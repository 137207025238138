.reportingInput__questionContainer {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
    overflow-x: auto !important;
}

.reportingInput__question {
    text-align: left;
    font-weight: 600;
    font-size: 16px !important;
    letter-spacing: 0.42px;
    color: #000000;
    display: flex;
    justify-content: "center";
    border: "1px solid red !important";
}

.reportingInput__questionNumber {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
}

.reportingInput__options {
    display: flex;
}
