.facilityCreationDesc {
  margin-left: 20px;
  align-content: left;
  text-align: left;
}

.facilityCreationDescList {
  text-align: left;
  font-size: 15px;
  margin-left: 0px;
}

.facilityCreationFormItem {
  background-color: white;
}

.messageContainer {
  background-color: #DAE3F1;
  width: 50%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
