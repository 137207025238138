.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 28px;
}

.cardContent {
  padding: 0 10px !important;
}

.section {
  border-bottom: 1px solid grey;
  padding: 0 10px;
  margin: 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.colorLabels {
  display: flex;
  padding-left: 20px;
  margin: 10px 0;
  align-items: center;
  font-size: small;
}

.colorLabels :not(:first-child) {
  margin-left: 10px;
}

.colorSample {
  height: 12px;
  width: 12px;
}

.loadingContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
