.dataCollectionInputs {
    text-align: left;
    padding: 10px 30px;
    min-height: 350px;
}

.dataCollectionActionButtons {
    padding: 15px;
    border-top: 1px solid grey;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}
