.sideBar_root {
    height: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 250px;
    left: 0;
    top: 0;
    flex: 1 1;
}

.sideBar_img {
    width: 133px;
    height: 80px;
}

.sideBar_header {
    padding: 12px;
}

.sideBarNav_root {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
}

.sideBarNav_nav {
    margin-bottom: 2px;
    overflow-y: scroll;
}

.sideBarNav_nav::-webkit-scrollbar {
    display: none;
}

.sideBar_divider {
    width: 90%;
    border-top: 1px solid #32E7A4;
    border-bottom: none;
}

.sideBarNav_list {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.accordionRoot {
    width: 100%;
    display: flex;
    justify-content: center;
}

.accordionRoot_lastChild {
    margin-bottom: 70px;
}

.sideBarNav_listItemSub {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
}

.sideBarNav_listItemSubSelected {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    border-right: 4px solid #32E7A4 !important;
}

.sideBarNav_subButton {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    min-height: 35px;
    width: 95% !important;
}

.sideBarNav_subButton .sideBarNav_title {
    margin-left: 10px;
    word-wrap: break-word;
    font-size: 13px;
    text-transform: none;
    letter-spacing: 0;
    font-weight: bold;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
