.sideBar_root {
  height: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 250px;
  flex: 1 1;
}

.sideBar_img_custom {
  width: 200px;
  height: 80px;
}

.sideBar_img {
  width: 133px;
  height: 80px;
}

.sideBar_header {
  padding: 12px;
}

.sideBar_heading {
  font-weight: 600;
  font-size: 16px;
}
