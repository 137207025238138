.actionRoot {
    width: 97%;
    margin: 0 auto;
}

.operationsDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}
