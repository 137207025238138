.governanceDashboard__loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}


.governanceDashboard__YearContainer {
    display: flex;
    align-items: left;
    margin-left: 50px;
    justify-content: left;
}

.governanceDashboard__YearText {
    display: flex;
    align-self: center;
    margin-right: 20px;
}