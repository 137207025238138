.reductionAnalytics_title {
    margin-left: 20px;
    text-align: start;
}

.reductionAnalytics_loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}

.reductionAnalytics_targetIcon {
    height: 50px;
}

.reductionAnalytics_chartDescription {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    text-align: start;
}

.reductionAnalytics_legendColor {
    height: 12px;
    width: 12px;
    margin-right: 10px;
    border-radius: 3px;
}

.reductionAnalytics_legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: small;
    margin-bottom: 20px;
}

.reductionAnalytics_legend>* {
    padding-right: 30px;
}

.reductionAnalytics_legendItem {
    display: flex;
    align-items: center;
}

.reductionAnalytics_tooltipContainer {
    padding: 8px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F5F5F5;
    border-radius: 5px;
}

.reductionAnalytics_tooltipContainer>p {
    margin: 0px;
    font: normal normal 600 10px/13px Montserrat;
    letter-spacing: 0.2px;
    color: #000000;
}

.reductionAnalytics_tooltipContainer>div {
    padding: 0px !important;
}

.reductionAnalytics_customToolTipContainer {
    display: flex;
    align-items: center;
    padding: 0px !important;
}

.reductionAnalytics_bulletContainer {
    height: 8px;
    width: 8px;
    margin-right: 4px;
    border-radius: 3px;
}

.reductionAnalytics_value {
    font: normal normal normal 10px/13px Montserrat;
    letter-spacing: 0.2px;
    color: #000000;
}
