.createReportDialog_input {
  background-color: white;
  opacity: 0.7;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #4d4d4d !important;
}

.createReportDialog_modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createReportDialog_modalBody>* {
  margin-top: 15px;
}

.createReportDialog_label {
  margin-top: 16px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
}
