.dataCollectionNavButtons {
    display: flex;
    justify-content: space-between;
    width: 93%;
    margin: 0 auto;
}

.dataCollectionContainer {
    margin: 8px auto;
    width: 93%;
    display: flex;
    flex-direction: column;
}

.dataPoint {
    border-bottom: 1px solid grey;
    display: flex;
    padding: 0px 30px;
    justify-content: space-between;
    align-items: center;
    justify-self: flex-end;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 40px;
    width: 330px;
    float: right;
}
