.topBarNav_list {
  display: flex;
  justify-content: space-evenly;
}

.topBarNav_item {
  height: 50px;
  margin-left: 8px;
}

.topBarNav_button {
  padding: 10px !important;
  width: 180px;
}

.topBarNav_title {
  margin-left: 10px;
  text-transform: none;
  font-weight: bold;
}