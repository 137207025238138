.reportCreationContainer {
    flex-direction: column;
    background-color: #EFF3F9;
    border: 1px solid #EFF3F9;
    margin: 0px 30px;
}

.container {
    background-color: #EFF3F9;
    width: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}

.autocompleteRoot {
    margin: 0 0 30px 0;
    width: 365px !important;
    height: 50px;
}

.autocomplete {
    width: 179px !important;
}

.createReportButtonContainer {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.buttons {
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons:last-child {
    margin-top: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createReportButton {
    width: 140px;
    height: 40px;
    background-color: #EFF3F9 !important;
    border: 1px solid #32E7A4 !important;
    border-radius: 7px;
    color: black !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}

.reportCreation_heading {
    padding-top: 30px;
    text-align: left;
    font-weight: 400;
    font-size: 30px;
}

.reportCreation_desc {
    width: 50%;
    padding-top: 30px;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #878787;
}

.reportCreationInfoContainer {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 40px;
    flex-wrap: nowrap;
    /* default value; can be omitted */
}

.reportCreationFormatImage {
    margin-left: 0px;
    margin-top: 0px;
    box-shadow: 0px 4px 8px rgba(21, 94, 162, 0.2);
    z-index: 0;
}

.reportCreationDetails {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(21, 94, 162, 0.2);
    border-radius: 10px;
    margin: 45px 0px 45px -10px;
    width: 100%;
}

.reportCreationInfo {
    padding: 30px 50px 30px 50px;
    margin-left: 50px;
    margin-right: 40px;
}

.reportCreationCompanyName {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    float: left;
}

.dateRange {
    float: right;
    justify-content: center;
    text-align: center;
    height: 20px;
    max-height: 50px !important;
}


.reportSummaryDetails {
    padding-top: 5px;
    padding-bottom: 5px;
    background: #EFF3F9;
    border-style: none none none solid;
    border-color: #64B5FC;
    border-radius: 0px 10px 10px 0px;
    width: 100%;
    margin-top: 50px;
    text-align: left;
}

.reportingFormatSelectionButton {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(21, 94, 162, 0.2);
    border-radius: 10px;
    width: 250px;
}

.customTemplateSelectionButton {
    background-color: #ffffff !important;
    box-shadow: 0px 4px 8px rgba(21, 94, 162, 0.2);
    border-radius: 10px !important;
    width: 250px;
}

.customTemplateSelectionButton:hover {
    background: #fcfafa7b !important;
    box-shadow: 0px 4px 8px rgba(21, 94, 162, 0.334);
}

.reportingFormat_img {
    width: 100%;
    height: 200px;
    padding-top: 10px;
}
