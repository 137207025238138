.loginPage_root {
  height: 100vh;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginParentDiv {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  width: 800px;
  position: relative;
  display: flex;
}

.loginDiv {
  background-color: #ffffff;
  width: 50%;
  min-height: 500px;
}

.messageContainer {
  background-color: #DAE3F1;
  width: 50%;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.forgetPassword {
  position: absolute;
  height: 100%;
  background: #ffffff;
  z-index: 1;
  width: 50%;
  right: 0;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
  -ms-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
}

.forgetPasswordActive {
  right: 50%;
}

.closeButton {
  position: absolute;
  left: 10px;
  top: 10px;
}
