.sideBarNav_nav {
  margin-bottom: 2px;
  overflow-y: scroll;
}

.sideBarNav_nav::-webkit-scrollbar {
  display: none;
}

.sideBarNav_root {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
}

.sideBarNav_list {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 500px !important;
}

.sideBarNav_icon {
  margin-left: 5px;
  margin-right: 5px;
}

.sideBarNav_mainButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90% !important;
  min-height: 70px !important;
  margin: 16px 0 !important;
}

.accordionRoot {
  width: 100%;
  display: flex;
  justify-content: center;
}

.accordionRoot_lastChild {
  margin-bottom: 70px;
}

.sideBarNav_subButton {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 35px;
  width: 90% !important;
}

.sideBarNav_subButton .sideBarNav_title {
  margin-left: 10px;
  word-wrap: break-word;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: bold;
  text-align: left;
}

.subButtonIcon {
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
}

.sideBarNav_mainButton .sideBarNav_title {
  margin-left: 10px;
  word-wrap: break-word;
  font-weight: 600;
  font-size: 15px;
  text-transform: none;
  letter-spacing: 0;
  width: 100px;
  text-align: center;
}

.sideBarNav_listItemMain {
  display: flex !important;
  justify-content: center !important;
  margin-top: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.sideBarNav_listItemMainSelected {
  display: flex !important;
  justify-content: center !important;
  margin-top: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border-right: 4px solid #32E7A4;
}

.sideBarNav__section {
  width: 100%;
}

.sideBarNav_mainButton {
  height: 100% !important;
}

.sideBarNav_listItemSub {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.sideBarNav_listItemSubSelected {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  border-right: 4px solid #32E7A4 !important;
}

.sideBar_divider {
  width: 85%;
  border-top: 1px solid #32E7A4;
  border-bottom: none;
}
