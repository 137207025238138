.genericTable_headRow {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

.genericTable_headCell {
    text-align: center;
    color: #000000;
}

.genericTable_container {
    overflow-x: hidden;
}

.genericTable_cardContent {
    padding: 0px;
}

.genericTable_tableDataRow {
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #F5F5F5;
}

.genericTable_tableCellData {
    text-align: center;
    color: #000000;
}

.genericTable_tableCard {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.genericTable_LegendRow {
    display: flex;
    height: 10px !important;
    align-items: center;
}

.genericTable_LegendIcon {
    border-radius: 2px;
    margin-right: 10px;
}

.genericTable_textHeadingConatiner {
    font-size: medium;
    font-weight: bold;
    padding-top: 22px;
    padding-left: 20px;
    padding-bottom: 16px;
    display: flex;
}

.genericTable_tableTitle {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    text-align: left;
    width: 60%;
}

@media (max-width:1024px) {
    .genericTable_container {
        overflow-x: scroll;
    }
}
