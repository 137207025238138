.targetDescContainer {
    margin-left: 20px;
    align-content: left;
    text-align: left;
    padding-top: 20px;
}

.targetDescHeading {
    font-size: 30px;
    font-weight: 400;
}

.targetCardHeading {
    font-size: 30px;
    font-weight: 400;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.targetCardContainer {
    margin: 15px;
    align-content: left;
    text-align: left;
}

.cardDesc {
    font-size: 16px;
}

.targetDesc {
    font-size: 20px;
    font-weight: 400;
    color: #878787;
}
