.profileInfo__mainContainer {
    display: flex;
}

.profileInfo__container {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
    margin-top: 50px;
    margin-left: 50px;
    width: 40%;
}

.companyInfo__container {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
    margin-top: 50px;
    margin-left: 50px;
    width: 40%;
}

.profileInfo__headingConatiner {
    padding-top: 22px;
    padding-left: 20px;
    display: flex;
}

.profileInfo__title {
    font-size: medium;
    text-align: left;
    font-weight: bold;
    width: 60%;
}

.profileInfo__row {
    margin-top: 4px !important;
    display: flex;
    justify-content: space-between;
}

.profileInfo__value {
    text-align: left;
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 0.42px;
    color: #000000;
}

.profileInfo__label {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
}