.reportListing_container {
    justify-content: space-between;
    padding: 0 50px;
    margin-top: 50px;
}

.reportListing_loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}
