.card {
    height: fit-content !important;
}

.cardContent {
    padding: 0 !important;
}

.tableWithPieChartOrBarChartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.tabs {
    float: left;
    font-weight: normal;
    padding-bottom: 4px;
    border-bottom: 2px solid #CECECE;
    margin-bottom: 20px;
}

.tabs span {
    height: 100%;
    padding-bottom: 4px;
    cursor: pointer;
}

.tabs .selected {
    height: 100%;
    border-bottom: 3px solid #32E7A4;
}

.tabs :not(:first-child) {
    margin-left: 20px;
}

.pieChartContainer {
    width: 100% !important;
}

.submitButton {
    margin-bottom: 20px !important;
    margin-right: 20px !important;

}
