.homeLayout_root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.homeLayout_content {
  background-color: white;
  margin-left: 250px;
  flex: 1;
}

.homeLayout_welcome {
  float: right;
  padding-right: 70px;
  padding-top: 18px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 15px;
}
