.genericLineChart_card {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.genericLineChart_textHeadingConatiner {
    font-size: medium;
    font-weight: bold;
    padding: 22px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
}

.genericLineChart_textSubHeadingConatiner {
    font-size: medium;
    font-weight: bold;
    padding: 0 20px 0px 20px;
    display: flex;
    justify-content: space-between;
}

.genericLineChart_title .genericLineChart_subTitle {
    color: black;
    font-size: medium;
    text-align: left;
    font-weight: bold;
    text-align: left;
    width: 60%;
}

.genericLineChart_cardContent {
    padding: 20px;
}

.colorLabels {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: small;
    margin-bottom: 20px;
}
  
.colorLabels > * {
    padding-right: 30px;
}

.colorSample {
    height: 12px;
    width: 12px;
    margin-right: 10px;
    border-radius: 3px;
}

.submitButton {
    margin-bottom: 20px !important;
}

.tabs {
    font-weight: normal;
    padding-bottom: 4px;
    border-bottom: 2px solid #CECECE;
}
  
.tabs span {
    height: 100%;
    padding-bottom: 4px;
    cursor: pointer;
}

.tabs .selected {
    height: 100%;
    border-bottom: 3px solid #32E7A4;
}

.tabs :not(:first-child) {
    margin-left: 10px;
}

.bottomText {
    font-size: medium;
}
