.createActionPlanDialog_loadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.createActionPlanDialog_actionPlanItems {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.createActionPlanDialog_dateRange {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createActionPlanDialog_to {
    padding-left: 10px;
    padding-right: 10px;
}

.createActionPlanDialog_actionItemsList {
    margin-top: 20px;
}

.createActionPlanDialog_actionItemError {
    color: #d32f2f;
}
