.recoveryPage_root {
  height: 100vh;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.recoveryDiv {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 50px; 
  width: 25%;
  background: #ffffff;
  border-radius: 5px;
}
