.createCustomReport_reportCreationContainer {
    display: flex;
}

.createCustomReport_reportCreationDescription {
    flex-direction: column;
    background-color: #EFF3F9;
    border: 1px solid #EFF3F9;
    margin: 20px 50px;
    width: 30%;
}

.createCustomReport_reportCreationHeading {
    padding-top: 30px;
    text-align: left;
    font-weight: 400;
    font-size: 30px;
}

.createCustomReport_reportCreationSubtitle {
    padding-top: 30px;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #878787;
}

.createCustomReport_reportCreationSectionsAndQuestions {
    width: 60%;
}

.createCustomReport_questionContainer {
    border: 1px solid #e2e2e2;
    margin-bottom: 10px;
}

.createCustomReport_question {
    text-align: left;
    font-weight: 600;
    font-size: 16px !important;
    letter-spacing: 0.42px;
    color: #000000;
}

.createCustomReport_questionNumber {
    text-align: left;
    font-size: 12px !important;
    letter-spacing: 0.28px;
    color: #595959;
    margin-bottom: 4px;
}

.createCustomReport_addMoreSectionButton {
    margin-top: 20px !important;
    margin-bottom: 25px !important;
}

.choiceContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.choiceActionContainer {
    width: 100%;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tableContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.columnOrRowInputs > h3 {
    float: left;
}
