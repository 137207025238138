.root {
    flex-direction: column;
}

.container {
    width: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}

.inputs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.autocompleteRoot {
    margin: 0 0 30px 0;
    width: 365px !important;
    height: 50px;
}

.autocomplete {
    width: 179px !important;
}

.dateRange {
    height: 20px;
    padding-left: 50px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    max-height: 50px !important;
}

.to {
    padding-left: 10px;
    padding-right: 10px;
}

.submitButton {
    width: 100px;
    height: 33px;
}

.buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}