.topNavBar_root {
  position: sticky;
  z-index: 1;
  display: flex;
  height: 70px;
  padding: 0px;
  margin-left: 250px;
  align-items: center;
  justify-content: space-between;
}

.profileSection {
  display: flex;
  justify-content: space-between;
  float: right;
}

.profileItems {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}

.profilePictureImg {
  color: #155EA2;
  height: 36px !important;
  width: 36px !important;
}
