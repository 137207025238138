.offsettingDescContainer {
  margin-left: 20px;
  align-content: left;
  text-align: left;
  padding-top: 20px;
}

.offsettingDescHeading {
  font-size: 30px;
  font-weight: 400;
}

.offsettingDesc {
  font-size: 20px;
  font-weight: 400;
  color: #878787;
}

.offsettingItem {
  font-weight: 500;
  font-size: 18px;
  padding: 20px 0px 20px 0px;
}

.offsettingProject_img {
  width: 100%;
  height: 200px;
  padding-top: 10px;
}

.offsettingItem_projectCard {
  font-size: 20px;
  margin: 10px 10px 10px 10px;
  width: 300px;
  height: 370px;
  display: inline-block;
}

.offsettingProject_projectCardImg {
  width: 150px;
  height: 150px;
}

.offsettingItem_projectCardChips {
  margin: 10px 10px 10px 10px;
}

.offsettingProjects_Container {
  width: "500px";
  overflow: scroll;
}

.offsettingProjects_InnerContainer {
  width: "10000px";
}