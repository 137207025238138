.card {
  margin-bottom: 10px;
  position: relative;
  height: 100%;
}

.summaryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.cardContent {
  padding: 0 10px !important;
}

.dateRange {
  height: 20px;
  padding: 10px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 50px !important;
}

.summary> :not(:last-of-type) {
  border-bottom: 1px solid grey;
}

.section {
  padding: 0;
  margin: 0;
}

.row {
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.summary p {
  margin: 8px;
}

.progressBarContainer {
  width: 100%;
  margin: 10px;
}

.progressBar {
  width: 20%;
  height: 16px;
}

.actionsButtons {
  display: flex;
  position: absolute;
  width: auto;
  right: 20px;
  left: 20px;
  bottom: 20px;
}
