.reportDetails__createContainer {
    margin: 20px 50px;
}

.reportDetails__title {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.42px;
    color: #000000;
}

.reportDetails__meta {
    font-weight: 400;
    font-size: 16px;
    align-self: center;
    letter-spacing: 0.42px;
    color: #000000;
}

.reportDetails_button {
    display: flex;
    gap: 12px;
}

.reportDetails__sectionTitle {
    font-weight: 600;
    font-size: 18px !important;
    letter-spacing: 0.42px;
    margin-right: 15px;
    color: #000000;
    text-align: left;
}

.reportDetails__loadingDiv {
    display: flex;
    margin-top: 250px;
    align-items: center;
    justify-content: center;
}

.reportDetails__metadataContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.reportDetails__sectionHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reportDetails__sectionStatus {
    margin-right: 8px;
}
