.root {
    flex-direction: column;
}

.actionButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 20px;
}
