.userManagementRoot {
  padding: 0 50px;
  margin-top: 30px;
}

.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 400px;
}

.modalBody {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actionButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20px;
}

.submitButton {
  display: flex;
  align-items: flex-end;
  float: right;
  margin-right: 15px;
}