
.modal_DialogTitleContainer {
  min-width: 400px;
  height: 50px;
  position: relative;
}

.modalTitleText {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  opacity: 1;
  position: absolute;
  align-self: center;
  width: 100%;
  left: 0;
}

.modal_DialogTitleCross {
  position: absolute;
  float: right;
}

.modal_dialogContainer {
  padding: 10px 50px !important;
}

.modal_btnContainer {
  text-align: center;
  margin: 20px;
}

.modalButton {
  width: 217px;
  height: 58px;
}
